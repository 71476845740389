/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

//luxe overides
var metaOverides = {

}

///*** Example what a product page would look like */
// var example = {
//   "n": "pages.page",
//   "title": "LUXE LEVELS - NAVY SILK DRESS BY AUR2230200158",
//   "useBackgroundImage": false,
//   "on": {
//     "published": "20231114101740",
//     "processed": "20240416183445",
//     "describePublished": "November 14, 2023"
//   },
//   "url": "http://localhost:8517/shop/items/navy-silk-dress-by-aur2230200158.html",
//   "i": {
//     "product": {
//       "id": "gid://shopify/Product/8553279127792",
//       "title": "NAVY SILK DRESS BY AUR2230200158",
//       "handle": "navy-silk-dress-by-aur2230200158",
//       "description": "",
//       "descriptionHtml": "",
//       "productType": "Women's Dresses",
//       "vendor": "AMANDA UPRICHARD",
//       "tags": [
//         "women clothing",
//         "women's dresses"
//       ],
//       "collections": [
//         "Women's Clothing",
//         "Women's Dresses"
//       ],
//       "media": {
//         "images": [
//           {
//             "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00438.jpg?v=1712271502",
//             "altText": "",
//             "position": 0
//           },
//           {
//             "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00425.jpg?v=1712271502",
//             "altText": "",
//             "position": 1
//           },
//           {
//             "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00428.jpg?v=1712271502",
//             "altText": "",
//             "position": 2
//           },
//           {
//             "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00431.jpg?v=1712271502",
//             "altText": "",
//             "position": 3
//           },
//           {
//             "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00441.jpg?v=1712271478",
//             "altText": "",
//             "position": 4
//           },
//           {
//             "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00465.jpg?v=1712271478",
//             "altText": "",
//             "position": 5
//           }
//         ],
//         "videos": [
//           {
//             "src": null,
//             "altText": "",
//             "position": 6,
//             "sources": [
//               {
//                 "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.HD-1080p-7.2Mbps-26899201.mp4",
//                 "mimeType": "video/mp4"
//               },
//               {
//                 "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.m3u8",
//                 "mimeType": "application/x-mpegURL"
//               },
//               {
//                 "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.HD-720p-4.5Mbps-26899201.mp4",
//                 "mimeType": "video/mp4"
//               },
//               {
//                 "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.SD-480p-1.5Mbps-26899201.mp4",
//                 "mimeType": "video/mp4"
//               }
//             ],
//             "poster": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/preview_images/20240313-DSC00438.jpg?v=1712589214"
//           }
//         ],
//         "models": [],
//         "externalVideos": []
//       },
//       "variants": [
//         {
//           "id": "gid://shopify/ProductVariant/45299961856240",
//           "title": "NAVY / XS",
//           "sku": "AUR2230200158XS",
//           "availableForSale": true,
//           "priceV2": {
//             "amount": "389.0",
//             "currencyCode": "USD"
//           },
//           "selectedOptions": [
//             {
//               "name": "Color",
//               "value": "NAVY"
//             },
//             {
//               "name": "Size",
//               "value": "XS"
//             }
//           ]
//         },
//         {
//           "id": "gid://shopify/ProductVariant/45299961889008",
//           "title": "NAVY / S",
//           "sku": "AUR2230200158S",
//           "availableForSale": false,
//           "priceV2": {
//             "amount": "389.0",
//             "currencyCode": "USD"
//           },
//           "selectedOptions": [
//             {
//               "name": "Color",
//               "value": "NAVY"
//             },
//             {
//               "name": "Size",
//               "value": "S"
//             }
//           ]
//         },
//         {
//           "id": "gid://shopify/ProductVariant/45299961921776",
//           "title": "NAVY / M",
//           "sku": "AUR2230200158M",
//           "availableForSale": false,
//           "priceV2": {
//             "amount": "389.0",
//             "currencyCode": "USD"
//           },
//           "selectedOptions": [
//             {
//               "name": "Color",
//               "value": "NAVY"
//             },
//             {
//               "name": "Size",
//               "value": "M"
//             }
//           ]
//         },
//         {
//           "id": "gid://shopify/ProductVariant/45299961954544",
//           "title": "NAVY / L",
//           "sku": "AUR2230200158L",
//           "availableForSale": true,
//           "priceV2": {
//             "amount": "389.0",
//             "currencyCode": "USD"
//           },
//           "selectedOptions": [
//             {
//               "name": "Color",
//               "value": "NAVY"
//             },
//             {
//               "name": "Size",
//               "value": "L"
//             }
//           ]
//         }
//       ],
//       "optionsByType": {
//         "Color": [
//           "NAVY"
//         ],
//         "Size": [
//           "XS",
//           "S",
//           "M",
//           "L"
//         ]
//       }
//     },
//     "namespace": "luxe.product"
//   },
//   "image": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00438.jpg?v=1712271502"
// };

//updated product object example 
// {
//     "id": "gid://shopify/Product/8969154265328",
//     "title": "Tweed Cropped Sequin Jacket ATTENTIF PARIS",
//     "handle": "tweed-cropped-sequin-jacket-by-attentif-paris-atp977039-01",
//     "description": "Elevate your wardrobe with the Tweed Cropped Sequin Jacket by ATTENTIF PARIS, a luxurious blend of elegance and modern flair. This sophisticated piece boasts a stunning cream tweed fabric adorned with delicate sequins, exuding timeless class and subtle shimmer. The intricately detailed gold buttons add a touch of opulence, while the cropped silhouette provides a contemporary twist, perfect for pairing with high-waisted trousers or skirts. Indulge in the exquisite craftsmanship and designer finesse of this jacket, seamlessly balancing chic sophistication with everyday wearability.",
//     "descriptionHtml": "<p>Elevate your wardrobe with the Tweed Cropped Sequin Jacket by ATTENTIF PARIS, a luxurious blend of elegance and modern flair. This sophisticated piece boasts a stunning cream tweed fabric adorned with delicate sequins, exuding timeless class and subtle shimmer. The intricately detailed gold buttons add a touch of opulence, while the cropped silhouette provides a contemporary twist, perfect for pairing with high-waisted trousers or skirts. Indulge in the exquisite craftsmanship and designer finesse of this jacket, seamlessly balancing chic sophistication with everyday wearability.</p>",
//     "productType": "101 W's Jackets and Blazers",
//     "availableForSale": true,
//     "createdAt": "2024-08-08T21:33:19Z",
//     "publishedAt": "2024-09-01T22:38:36Z",
//     "totalInventory": 2,
//     "vendor": "ATTENTIF PARIS",
//     "tags": [
//         "blazers",
//         "trending",
//         "women blazer",
//         "Women's Blazers",
//         "WOMENS BLAZER"
//     ],
//     "trackingParameters": null,
//     "seo": {
//         "title": null,
//         "description": null
//     },
//     "priceRange": {
//         "minVariantPrice": {
//             "amount": "269.0",
//             "currencyCode": "USD"
//         },
//         "maxVariantPrice": {
//             "amount": "269.0",
//             "currencyCode": "USD"
//         }
//     },
//     "collections": [
//         "Women's Clothing",
//         "Women's Tops",
//         "Women's Jackets"
//     ],
//     "options": [
//         {
//             "id": "gid://shopify/ProductOption/11691233313008",
//             "name": "Color",
//             "values": [
//                 "CREAM"
//             ]
//         },
//         {
//             "id": "gid://shopify/ProductOption/11691233345776",
//             "name": "Size",
//             "values": [
//                 "S",
//                 "M",
//                 "L"
//             ]
//         }
//     ],
//     "media": {
//         "images": [
//             {
//                 "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240813-DSC04370use.sdr.jpg?v=1725027916",
//                 "altText": "",
//                 "position": 0
//             },
//             {
//                 "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240813-DSC04397.sdr.jpg?v=1725027612",
//                 "altText": "",
//                 "position": 2
//             },
//             {
//                 "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240813-IMG_6783.sdr.jpg?v=1725027624",
//                 "altText": "",
//                 "position": 3
//             },
//             {
//                 "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240813-DSC04371.sdr.jpg?v=1725028401",
//                 "altText": "",
//                 "position": 4
//             }
//         ],
//         "videos": [
//             {
//                 "src": null,
//                 "altText": "",
//                 "position": 1,
//                 "sources": [
//                     {
//                         "url": "https://orders.luxelevels.com/cdn/shop/videos/c/vp/1ab8572362d3405ca88d2f308294141e/1ab8572362d3405ca88d2f308294141e.HD-1080p-4.8Mbps-33983488.mp4",
//                         "mimeType": "video/mp4"
//                     },
//                     {
//                         "url": "https://orders.luxelevels.com/cdn/shop/videos/c/vp/1ab8572362d3405ca88d2f308294141e/1ab8572362d3405ca88d2f308294141e.m3u8",
//                         "mimeType": "application/x-mpegURL"
//                     },
//                     {
//                         "url": "https://orders.luxelevels.com/cdn/shop/videos/c/vp/1ab8572362d3405ca88d2f308294141e/1ab8572362d3405ca88d2f308294141e.HD-720p-3.0Mbps-33983488.mp4",
//                         "mimeType": "video/mp4"
//                     },
//                     {
//                         "url": "https://orders.luxelevels.com/cdn/shop/videos/c/vp/1ab8572362d3405ca88d2f308294141e/1ab8572362d3405ca88d2f308294141e.SD-480p-1.2Mbps-33983488.mp4",
//                         "mimeType": "video/mp4"
//                     }
//                 ],
//                 "poster": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/preview_images/20240813-DSC04371.sdr.jpg?v=1725028456"
//             }
//         ],
//         "models": [],
//         "externalVideos": []
//     },
//     "variants": [
//         {
//             "id": "gid://shopify/ProductVariant/46453888745712",
//             "title": "CREAM / S",
//             "sku": "ATP977039-01S",
//             "availableForSale": false,
//             "priceV2": {
//                 "amount": "269.0",
//                 "currencyCode": "USD"
//             },
//             "selectedOptions": [
//                 {
//                     "name": "Color",
//                     "value": "CREAM"
//                 },
//                 {
//                     "name": "Size",
//                     "value": "S"
//                 }
//             ]
//         },
//         {
//             "id": "gid://shopify/ProductVariant/46453888778480",
//             "title": "CREAM / M",
//             "sku": "ATP977039-01M",
//             "availableForSale": false,
//             "priceV2": {
//                 "amount": "269.0",
//                 "currencyCode": "USD"
//             },
//             "selectedOptions": [
//                 {
//                     "name": "Color",
//                     "value": "CREAM"
//                 },
//                 {
//                     "name": "Size",
//                     "value": "M"
//                 }
//             ]
//         },
//         {
//             "id": "gid://shopify/ProductVariant/46453888811248",
//             "title": "CREAM / L",
//             "sku": "ATP977039-01L",
//             "availableForSale": true,
//             "priceV2": {
//                 "amount": "269.0",
//                 "currencyCode": "USD"
//             },
//             "selectedOptions": [
//                 {
//                     "name": "Color",
//                     "value": "CREAM"
//                 },
//                 {
//                     "name": "Size",
//                     "value": "L"
//                 }
//             ]
//         }
//     ],
//     "startingPrice": "$ 269.00",
//     "normalPrice": 269,
//     "skus": [
//         "ATP977039-01L"
//     ],
//     "orginalTitle": "Tweed Cropped Sequin Jacket by ATTENTIF PARIS ATP977039-01",
//     "optionsByType": {
//         "Color": [
//             "CREAM"
//         ],
//         "Size": [
//             "L"
//         ]
//     }
// }

var jsonRender = require("../../uat/src/interface/jsonRender.js");
var uai = require("../../uat/src/interface/interface.js");
var events = require("../../uam/events.js");
var carousel = require("../../uat/src/elements/ua.carousel.js");
var shopifySearch = require("../../uam/shopify.search.js");
var shopifyCart = require("../../uam/shopify.cart.js");
var conformPrice = require("../../uam/functions/conformCurrencyEcommerce.js").function;

var define = {
    namespace: "luxe.product",
    title: "Luxe Leveles Product Listing Page",
    description: "Display's a product and it's details.",
    fields: {
        product: {
            type: "object",
            description: "The simplified content of the product as provided by shopify (without the edges)."
        }, productid: {
            type: "string",
            description: "The product id of the product.",
            required: true,
            addtouae: true
        },
        hash: {
            type: "string",
            description: "The hash representing the product.",
            addtouae: true
        }
    }//,
    // supportServer: false
}; module.exports.define = define;

/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
async function render(options) {

    if (!options.product) {
        //get the prioduuct
        options.product = await shopifySearch.search({
            product: options.productid
        });
    }

    if ("availableForSale" in options.product) {

        if (!(options.product.availableForSale)) {

            return {
                n: "luxe.error",
                product: product,
                productid: product.id,
                type: "product.unavailable"
            };

        }
    }

    var previewid = uai.generateRandomID(36);

    /**
     * The main container for the product.
     */
    var product = options.product;

    // seperate the title from the product
    // the last space is the seperator
    //    var prodTitleInfo = product.title;

    // check for variants and get the  skus

    // var variants = product.variants;
    // var skus = [];
    // if (product.variants) {
    //     for (var i = 0; i < variants.length; i++) {
    //         skus.push(variants[i].sku);
    //     }
    // }

    // product.skus = skus;

    var firstImage = false;

    //is their a image
    if (product.media.images) {
        if (product.media.images.length > 0) {
            firstImage = product.media.images[0];
        }
    }

    var tags = [""];
    if (product.tags) {
        for (var i = 0; i < product.tags.length; i++) {
            var tag = shopifySearch.FindTag(product.tags[i]);
            tags.push({
                n: "a",
                c: "badge bg-secondary me-1 text-uppercase",
                href: "../../../../../../../tag/" + tag.handle,
                i: tag.name
            });
        }

        tags = {
            n: "div",
            c: " pt-3 pb-3",
            i: [
                tags
            ]
        };

    }

    var relevantProducts = [""];

    relevantProducts = {
        n: "div",
        id: "shop-relevant-products",
        c: "p-2 ",
        i: [
            {
                "n": "h1",
                "c": "p-2",
                "i": [{
                    "n": "span",
                    "i": "Styles we know you'll love,",
                    "c": "text-break ",
                    "s": ""
                }, {
                    "n": "div",
                    "c": "row pt-3 pb-3 d-none",
                    "i": {
                        "n": "div",
                        "c": "col-4 border-bottom border-primary border-5",
                        "i": ""
                    }
                }]
            },
            {
                "n": "luxe.search",
                "relevantproducts": product.id
            }
        ]
    };

    //    //is their a product video?
    //     if (product.media.videos && product.media.videos.length > 0) {
    //         var vid = product.media.videos[0];
    //         mainPreview = await CreateVideoPlayer(vid);

    //     } else if (firstImage) {
    //         mainPreview = {
    //             n: "img",
    //             src: firstImage.src,
    //             alt: product.title,
    //             class: "img-fluid"
    //         };
    //     } else {
    //         mainPreview = {
    //             n: "div",
    //             c: "bg-secondary",
    //             i: "This product is a mystery."
    //         };
    //     }

    /** Build the Product Selection */
    //for each optionsByType create a ua.select.group
    var addtoCardDiv = await BuildProductSelection(product);


    // console.log("description", product.descriptionHtml);

    var descriptionJson = {
        "n": "div",
        c: "d-none",
        "i": "&nbsp;"
    };

    if (product.descriptionHtml.trim() !== "") {
        console.log(`Attempting to convert HTML to JSON: "${product.descriptionHtml}"`);
        descriptionJson = jsonRender.convertHTML(product.descriptionHtml);

        descriptionJson = {
            n: "div",
            c: " pt-3 pb-3",
            i: [
                {
                    "n": "h1",
                    "c": "p-0",
                    "i": [{
                        "n": "span",
                        "i": "Description",
                        "c": "text-break ",
                        "s": ""
                    }, {
                        "n": "div",
                        "c": "row pt-3 pb-3 d-none",
                        "i": {
                            "n": "div",
                            "c": "col-4 border-bottom border-primary border-5",
                            "i": ""
                        }
                    }]
                },
                descriptionJson
            ]
        }
    }

    console.log("Description JSON", descriptionJson, product);

    /** get media list */
    var mediaList = ""; // [];

    var mediaPreviewColumnClass = "col-3 mb-3";
    var mainPreview = createMediaCarousel2(product);


    //for each video, add a play icon, for each image make a small preview col-2
    // if (product.media.videos) {
    //     product.media.videos.forEach(function (video) {
    //         video.alt = product.title;
    //         mediaList.push({
    //             n: "div",
    //             "data-image-preview": "true",
    //             c: `${mediaPreviewColumnClass} position-relative border-primary border-2 mb-1`,
    //             s: ``,
    //             "data-product-preview-video": JSON.stringify(video),
    //             "data-preview-id": previewid,
    //             "data-product-thumbnail": "true",
    //             i: [
    //                 {
    //                     n: "img",
    //                     src: video.poster,
    //                     alt: product.title,
    //                     class: "img-fluid",
    //                     "data-product-preview-video": JSON.stringify(video),
    //                     "data-preview-id": previewid,
    //                 },
    //                 {
    //                     n: "div",
    //                     s: "position:absolute;top:0;bottom:0;display:flex;/* width: 100%; *//* left: 0px; *//* right: 0px; */",
    //                     i: {
    //                 //place an icon in the middle of the div
    //                     n: "i",
    //                     c: "bi bi-play-circle col-11 fs-1 p-2 text-shadow bg-50-black",
    //                     s: `
    //                         display: flex;
    //                         justify-content: center;
    //                         /* align-content: center !important; */
    //                         align-items: center;
    //                         color: #fff;
    //                     `,
    //                     i: "&nbsp;",
    //                     "data-product-preview-video": JSON.stringify(video),
    //                     "data-preview-id": previewid,
    //                 }
    //             }]
    //         });
    //     });
    //}

    // if (product.media.images) {
    //     product.media.images.forEach(function (image) {
    //         mediaList.push({
    //             n: "div",
    //             c: `${mediaPreviewColumnClass} border-primary border-2 mb-1`,
    //             "data-product-thumbnail": "true",
    //             "data-image-preview": "true",
    //             i: {
    //                 n: "img",
    //                 src: image.src,
    //                 alt: product.title,
    //                 class: "img-fluid",
    //                 "data-product-preview-image": "true",
    //                 "data-preview-id": previewid
    //             }
    //         });
    //     }
    //     );
    // }

    var previewHolster = {
        n: "div",
        c: "row position-relative",
        i: [{
            n: "div",
            c: "row row-reset",
            // "data-product-preview": "true",
            id: previewid,
            i: mainPreview
        }, {
            n: "div",
            c: "position-absolute top-0 start-0 col-12",
            i: []
        }, {
            n: "a",
            c: "product-add-to-cart btn btn-outline-primary bg-black text-white text-black-hover position-absolute col-4 bottom-0 end-0 m-3 d-none ua-a-incenter",
            style: "z-index: 500;",
            href: "#add-to-card",
            i: [{
                n: "i",
                c: "bi bi-cart-check",
                i: "&nbsp;"
            }, "Add to Cart"]
        }]
    }

    /**
     * build breadcrumbs
     * pruducttype / productvendor / producttitle
     */

    var breadcrumb = GenerateBreadcrumbs(product);
    var mobileBreadcrumbs = { ...breadcrumb };
    breadcrumb.c += " d-none d-md-block";
    mobileBreadcrumbs.c += " ";

    var sharer = GenerateSharer(product, firstImage);


    var priceing = product.startingPrice;

    //if I have the tag "the-outlet" then I want to show orginal price slashed through and the new price of 30% off next to it
    if (product.tags.includes("The Outlet")) {

        var discountPricing = product.startingPrice.replace("$", "");
        discountPricing = parseFloat(discountPricing);
        discountPricing = discountPricing - (discountPricing * .30);
        discountPricing = conformPrice(discountPricing);

        priceing = [
            {
                n: "span",
                c: "on-sale-pricing",
                i: [
                    {
                        n: "span",
                        // c: "p-0 m-0",
                        c: "p-0 m-0 text-decoration-line-through sale-old-price",
                        i: product.startingPrice
                    }
                    , "&nbsp;", {
                        n: "span",
                        c: "p-0 m-0 sale-new-price",
                        i: discountPricing
                    }
                ]
            },
            {
                n: "p",
                c: "fs-3 fw-bold text-left p-3 rounded text-black d-md-inline text-center",
                s: "background-color: rgb(198, 164, 92);",
                i: "Save 30% Use Code LUXE30 at Checkout"
            }];
    }

    var ele = [{
        "n": "product",
        "data": JSON.stringify(product),
        // "c": "pt-3 pb-3",
        "i":
            [
                {
                    "n": "div",
                    "c": "row d-none",
                    i: breadcrumb
                },
                {
                    "n": "div",
                    "c": "row",
                    "i": [{
                        "n": "div",
                        "c": "col-12 col-md-7 m-0 p-0 luxe-product-preview-side-left",
                        "i": [
                            previewHolster
                        ]
                    }, {
                        "n": "div",
                        "c": "col-12 col-md-5 luxe-product-preview-side-right",
                        "i": [{
                            "n": "div",
                            "c": "row",
                            "id": previewid + "-media-list",
                            "data-media-list": "true",
                            "i": [{
                                "n": "div",
                                c: "d-block d-md-none ps-2",
                                i: "&nbsp;"
                            }, mediaList]
                        }, {
                            "n": "div",
                            "c": "row",
                            "i": {
                                "n": "div",
                                "c": "col-12 border-bottom border-primary border-5 d-md-none mb-3 d-none",
                                "i": ""
                            }
                        }, mobileBreadcrumbs, {
                            "n": "div",
                            "c": "row d-none",
                            "i": {
                                "n": "div",
                                "c": "col-4 border-bottom border-primary border-5 mb-5",
                                "i": ""
                            }
                        }, {
                            "n": "h1",
                            // "data-product-adornment-title": "true",
                            "c": "",
                            "i": [{
                                "n": "span",
                                "i": product.title,
                                "c": "text-break "
                            }, {
                                "n": "div",
                                "c": "row pt-3 d-none",
                                "i": {
                                    "n": "div",
                                    "c": "col-4 d-none border-bottom border-primary border-5 d-md-none",
                                    "i": ""
                                }
                            }]
                        }, {
                            "n": "p",
                            "c": "fs-3 fw-bold text-left",
                            i: priceing
                        }, addtoCardDiv, descriptionJson, tags, sharer]

                    }]
                }]
    }, relevantProducts];

    await UpdateMetaData(product);

    return ele;

} module.exports.render = render;

async function UpdateMetaData(product) {
    var meta = GenerateMetaData(product);
    var scriptElement = document.createElement("script");
    scriptElement.type = "application/ld+json";
    scriptElement.innerHTML = JSON.stringify(meta);
    document.head.appendChild(scriptElement);
}

function GenerateMetaData(product) {

    //update the "application/ld+json"
    //Exmaple LD+JSON
    // var example = {
    //     "@context": "https://schema.org/",
    //     "@type": "Product",
    //     "name": "Executive Anvil",
    //     "weight": {
    //         "@type": "QuantitativeValue",
    //         "minValue": 1.0,
    //         "maxValue": 5.0
    //     },
    //     "image": [
    //         "https://example.com/photos/1x1/photo.jpg",
    //         "https://example.com/photos/4x3/photo.jpg",
    //         "https://example.com/photos/16x9/photo.jpg"
    //     ],
    //     "video": {
    //         "@type": "VideoObject",
    //         "name": "Executive Anvil Product Demonstration",
    //         "description": "Watch the Executive Anvil in action and learn how it can be the perfect tool for your business needs.",
    //         "thumbnailUrl": "https://example.com/thumbnails/anvil-demo.jpg",
    //         "uploadDate": "2024-10-01",
    //         "contentUrl": "https://example.com/videos/anvil-demo.mp4",
    //         "embedUrl": "https://example.com/embed/anvil-demo"
    //     },
    //     "description": "Sleeker than ACME's Classic Anvil, the Executive Anvil is perfect for the business traveler looking for something to drop from a height.",
    //     "sku": "0446310786",
    //     "mpn": "925872",
    //     "brand": {
    //         "@type": "Brand",
    //         "name": "ACME"
    //     },
    //     "offers": {
    //         "@type": "Offer",
    //         "url": "https://example.com/anvil",
    //         "priceCurrency": "USD",
    //         "price": 119.99,
    //         "priceValidUntil": "2024-11-20",
    //         "itemCondition": "https://schema.org/UsedCondition",
    //         "availability": "https://schema.org/InStock",
    //         "shippingDetails": {
    //             "@type": "OfferShippingDetails",
    //             "shippingRate": {
    //                 "@type": "MonetaryAmount",
    //                 "value": 3.49,
    //                 "currency": "USD"
    //             },
    //             "shippingDestination": {
    //                 "@type": "DefinedRegion",
    //                 "addressCountry": "US"
    //             },
    //             "deliveryTime": {
    //                 "@type": "ShippingDeliveryTime",
    //                 "handlingTime": {
    //                     "@type": "QuantitativeValue",
    //                     "minValue": 0,
    //                     "maxValue": 2,
    //                     "unitCode": "DAY"
    //                 },
    //                 "transitTime": {
    //                     "@type": "QuantitativeValue",
    //                     "minValue": 3,
    //                     "maxValue": 14,
    //                     "unitCode": "DAY"
    //                 }
    //             }
    //         },
    //         "hasMerchantReturnPolicy": {
    //         "@type": "MerchantReturnPolicy",
    //         "applicableCountry": "US",
    //         "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
    //         "merchantReturnDays": 30,
    //         "returnMethod": [
    //             "https://schema.org/ReturnByMail",
    //             "https://schema.org/ReturnInStore"
    //         ],
    //         "returnFees": "https://schema.org/FreeReturn",
    //         "additionalProperty": [
    //             {
    //                 "@type": "PropertyValue",
    //                 "name": "Online Return Window",
    //                 "value": "Full refund within 14 days from the shipping date; merchandise credit within 30 days from the shipping date. No returns after 30 days."
    //             },
    //             {
    //                 "@type": "PropertyValue",
    //                 "name": "In-Store Return Policy",
    //                 "value": "Exchange or store credit within 14 days of purchase."
    //             },
    //             {
    //                 "@type": "PropertyValue",
    //                 "name": "Exchanges",
    //                 "value": "Exchanges for online purchases can be completed in-store or by mail within the online return window."
    //             },
    //             {
    //                 "@type": "PropertyValue",
    //                 "name": "Exceptions",
    //                 "value": "Jewelry and intimates are final sale. Dresses require a 15% restocking fee and must have the original tag attached for returns within the return window."
    //             },
    //             {
    //                 "@type": "PropertyValue",
    //                 "name": "Sale Merchandise",
    //                 "value": "All sale and discounted items are final and not subject to return."
    //             }]
    //         }
    //     }
    // };

    // if the product has a sale price add a price specification to offerds
    // "offers": {
    //    {...}
    //     "priceSpecification": {
    //       "@type": "UnitPriceSpecification",
    //       "priceType": "https://schema.org/ListPrice",
    //       "price": 15.00,
    //       "priceCurrency": "GBP"
    //     }
    //   }

    // Base structure
    const productLDJSON = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": product.title,
        "description": product.description,
        "sku": product.skus[0], // Assuming the first SKU as the main one
        "brand": {
            "@type": "Brand",
            "name": product.vendor
        },
        //add weight from 1 - 3 lbs
        "weight": {
            "@type": "QuantitativeValue",
            "value": product.weight || 3,
            "unitCode": "LB"
        },
        "image": product.media.images.map(image => image.src),
        "offers": {
            "@type": "Offer",
            "url": `https://luxelevels.com/product/${product.handle}`, // Replace with actual product URL if available
            "priceCurrency": product.priceRange.minVariantPrice.currencyCode,
            "price": product.priceRange.minVariantPrice.amount,
            "availability": product.availableForSale ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
            "itemCondition": "https://schema.org/NewCondition",
            "shippingDetails": {
                "@type": "OfferShippingDetails",
                "shippingRate": {
                    "@type": "MonetaryAmount",
                    "value": 19.00, // Example flat rate, modify as needed
                    "currency": product.priceRange.minVariantPrice.currencyCode
                },
                "shippingDestination": {
                    "@type": "DefinedRegion",
                    "addressCountry": "US"
                },
                "deliveryTime": {
                    "@type": "ShippingDeliveryTime",
                    "handlingTime": {
                        "@type": "QuantitativeValue",
                        "minValue": 1,
                        "maxValue": 3,
                        "unitCode": "DAY"
                    },
                    "transitTime": {
                        "@type": "QuantitativeValue",
                        "minValue": 3,
                        "maxValue": 14,
                        "unitCode": "DAY"
                    }
                }
            },
            "hasMerchantReturnPolicy": {
                "@type": "MerchantReturnPolicy",
                "applicableCountry": "US",
                "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
                "merchantReturnDays": 30,
                "returnMethod": [
                    "https://schema.org/ReturnByMail",
                    "https://schema.org/ReturnInStore"
                ],
                "returnFees": "https://schema.org/FreeReturn",
                "additionalProperty": [
                    {
                        "@type": "PropertyValue",
                        "name": "Online Return Window",
                        "value": "Full refund within 14 days from the shipping date; merchandise credit within 30 days from the shipping date. No returns after 30 days."
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "In-Store Return Policy",
                        "value": "Exchange or store credit within 14 days of purchase."
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Exchanges",
                        "value": "Exchanges for online purchases can be completed in-store or by mail within the online return window."
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Exceptions",
                        "value": "Jewelry and intimates are final sale. Dresses require a 15% restocking fee and must have the original tag attached for returns within the return window."
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Sale Merchandise",
                        "value": "All sale and discounted items are final and not subject to return."
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Contact",
                        "value": "To start the return or exchange process, contact us."
                    }
                ]
            }
        },
        "additionalProperty": []
    };

    // Add product options if available
    if (product.options && product.options.length > 0) {
        productLDJSON.additionalProperty = product.options.map(option => ({
            "@type": "PropertyValue",
            "name": option.name,
            "value": option.values.join(", ")
        }));
    }

    // // Include video details as additional properties
    // if (product.media.videos && product.media.videos.length > 0) {
    //     product.media.videos.forEach((video, index) => {
    //         productLDJSON.additionalProperty.push({
    //             "@type": "PropertyValue",
    //             "name": `Video ${index + 1}`,
    //             "value": `Video URL: ${video.sources[0]?.url || ''}, Poster: ${video.poster || ''}`
    //         });
    //     });
    // }

    // // Add variants if available
    // if (product.variants && product.variants.length > 0) {
    //     productLDJSON.offers.offers = product.variants.map(variant => ({
    //         "@type": "Offer",
    //         "sku": variant.sku,
    //         "priceCurrency": variant.priceV2.currencyCode,
    //         "price": variant.priceV2.amount,
    //         "availability": variant.availableForSale ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
    //         "itemCondition": "https://schema.org/NewCondition",
    //         "url": `https://luxelevels.com/product/${product.handle}?variant=${variant.id}` // Modify with actual URL if needed
    //     }));
    // }

    // Add sale price if applicable
    if (product.priceRange.minVariantPrice.amount < product.normalPrice) {
        productLDJSON.offers.priceSpecification = {
            "@type": "UnitPriceSpecification",
            "priceType": "https://schema.org/SalePrice",
            "price": product.priceRange.minVariantPrice.amount,
            "priceCurrency": product.priceRange.minVariantPrice.currencyCode
        };
    }
    return productLDJSON;
}

var generateHash = require("../../uam/functions/generateHash.js").function;

events.on("interface.afterrender", async function () {

    //is the search element prerendered - update it!
    if (!(uai.server())) {
        // console.log("Everything is rendered.");
        var uae = document.querySelector(`uae [namespace="${define.namespace}"]`);
        if (uae) {
            var productid = uae.getAttribute("productid");
            // console.log("Product ID", productid);
            var product = await shopifySearch.search({
                product: productid
            });

            //if it's an array get the first one
            if (product.length == 1) {
                product = product[0];
            } else if (product.length == 0) {
                console.log("Product not found.", productid);
                var ele = await jsonRender.render({
                    n: "luxe.error",
                    product: product,
                    productid: product.id,
                    type: "product.notfound"
                });
            }

            if (!(product.availableForSale)) {
                console.log("Product is not available for sale.", {
                    availableForSale: product.availableForSale,
                    product: product,
                    productid: productid
                });

                uae.innerHTML = "";
                var ele = await jsonRender.render({
                    n: "luxe.error",
                    product: product,
                    productid: product.id,
                    type: "product.unavailable"
                });
                uae.appendChild(ele);
                return;
            }

            var hash = generateHash(product);

            if (uae.getAttribute("hash") == hash) {
                console.log("Product hash did not change.")
                return;
            } else {
                console.log("Product hash changed.", hash);
            }

            // console.log("Product", product);
            // uae.setAttribute("product", JSON.stringify(product));
            uae.innerHTML = "";

            var options = {
                product: product,
                productid: productid
            }

            console.log("Updating product page...", options);

            var ele = await jsonRender.render(await render(options));
            console.log("Updating product element", ele);

            uae.appendChild(ele);

            //if the hash in the query is add-to-cart move add-to-cart to the 20% from the top of the screen
            if (window.location.hash === "#add-to-cart") {
                var addtoCard = document.getElementById("add-to-cart");
                if (addtoCard) {
                    var top = addtoCard.getBoundingClientRect().top;
                    console.log("Scrolling to top", top);
                    window.scrollTo({
                        top: top - 200,
                        behavior: 'smooth'
                    });
                }
            }

            //activate the carousel
            setTimeout(function () {
                carousel.ActivateCarousel();
            }, 1000);

        }

    }

});



//add a document event listener for clicking on the play button
// document.addEventListener("click", function (event) {
//     if (event.target.matches("[data-product-preview-video]")) {
//         PlayVideo(event);
//     } else if (event.target.matches("[data-product-preview-image]")) {
//         ChangeImage(event);
//     }
// });

// async function ChangeImage(event) {

//     var mediaPreview = document.getElementById(event.target.getAttribute("data-preview-id"));
//     var image = event.target;

//     //if the image is a string try to parse it
//     if (typeof image === "string") {
//         try {
//             image = JSON.parse(image);
//         } catch (e) {
//             console.error("Failed to parse image", image);
//             return null;
//         }
//     }

//     var image = await jsonRender.render(CreateImagePreview(image));

//     //replace the media preview with the image
//     //clear the media preview
//     mediaPreview.innerHTML = "";
//     mediaPreview.appendChild(image);
//     HighlightPreview(event.target);

// }

// async function HighlightPreview(target) {

//     //get the media list holder
//     var mediaList = target.closest("[data-media-list]");

//     //reset the border on all previews
//     mediaList.querySelectorAll("[data-product-thumbnail]").forEach(function (preview) {
//         // preview.classList.remove("border-bottom");
//         preview.style.opacity = 1;
//     });

//     //get nearest data-image-preview
//     var preview = target.closest("[data-product-thumbnail]");

//     //add border-bottom to the target
//     // preview.classList.add("border-bottom");
//     preview.style.opacity = .5;

//     //check if there is a data-product-adornment-title
//     var adornmentTitle = document.body.querySelector("[data-product-adornment-title]");
//     // console.log("Title", adornmentTitle);
//     if (adornmentTitle) {
//         adornmentTitle.style.opacity = 0; 
//     }

//     var title = document.body.querySelector("[data-product-title]");
//     console.log("title", title);
//     title.classList.remove("d-none");

//     //scroll to top of the body in .3 seconds
//     window.scrollTo({
//         top: 0,
//         behavior: 'smooth'
//     });

// }

// async function PlayVideo(event) {

//     // console.log ("Playing Video", event.target);
//     var mediaPreview = document.getElementById(event.target.getAttribute("data-preview-id"));
//     var video = event.target.getAttribute("data-product-preview-video");

//     //if the video is a string try to parse it
//     if (typeof video === "string") {
//         try {
//             console.log("Parsing Video", video);
//             video = JSON.parse(video);
//         } catch (e) {
//             console.error("Failed to parse video", video);
//             return null;
//         }
//     }


//     //create the video player
//     var player = await jsonRender.render(CreateVideoPlayer(video));

//     //replace the media preview with the video player
//     //clear the media preview
//     mediaPreview.innerHTML = "";
//     mediaPreview.appendChild(player);
//     HighlightPreview(event.target);

// }

function CreateImagePreview(image) {

    return {
        n: "img",
        src: image.src,
        // alt: image.alt,
        class: "img-fluid"
    };


}

function CreateVideoPlayer(vid) {

    console.log("Creating Video Player", vid);

    var mainPreview = {};

    if (!vid.src) {

        //get m3u8
        var m3u8 = vid.sources.find(function (source) {
            return source.mimeType === "application/x-mpegURL";
        });

        var mp4s = vid.sources.filter(function (source) {
            return source.mimeType === "video/mp4";
        });

        // console.log("M3U8", m3u8);

        mainPreview = {
            n: "video",
            c: "object-fit-cover col-12  p-0 m-0",
            autoplay: "autoplay",
            loop: "loop",
            muted: "muted",
            playsinline: "playsinline",
            preload: "auto",
            poster: vid.poster,
            alt: vid.alt,
            i: [{
                n: "source",
                src: m3u8.url,
                type: m3u8.mimeType
            }, {
                n: "source",
                src: mp4s[1].url,
                type: mp4s[1].mimeType
            }]
        };

    } else {
        //embed the source using an iframe
        mainPreview = {
            n: "iframe",
            src: vid.src,
            allow: "autoplay; fullscreen",
            allowfullscreen: "allowfullscreen",
            c: "object-fit-cover col-12 p-0 m-0",
            frameborder: "0",
            scrolling: "no",
            class: "w-100"
        };
    }

    return mainPreview;
}

async function BuildProductSelection(product) {
    var addtoCardDiv = {
        n: "div",
        id: "add-to-cart",
        i: []
    };

    // product.id = gid://shopify/Product/8561651155184 - get just the number at the end
    var id = product.id.split("/").pop();
    console.log("SPID", id);

    var holdingLabel = {
        n: "label",
        i: ``,
        c: "pb-0 fs-4 d-block"
    }

    //add skus
    if ("skus" in product) {
        holdingLabel.i = `SKU: ${product.skus[0]}`;
    }

    var qtyLeft = {
        n: "label",
        i: `Items: ${product.qty}`,
        c: "pb-0 fs-4 d-block"
    }

    if (product.qty < 2) {
        qtyLeft.i = `Last Item!`;
    } else if (product.qty < 1) {
        qtyLeft.i = `Sold Out`;
    } else {
        qtyLeft.c = "d-none";
    }

    addtoCardDiv.i.push(holdingLabel, qtyLeft);

    console.log("Building Product selection", product, product.optionsByType);

    //use options 

    // Assuming 'product' and 'options' are already defined and available

    if ("optionsByType" in product) {

        var options = product.options;

        // Step 1: Create a lookup map from the options array
        var optionLookup = {};
        for (var i = 0; i < options.length; i++) {
            optionLookup[options[i].name] = options[i].values;
        }

        // Step 2: Get the keys of optionsByType
        var keys = Object.keys(product.optionsByType);

        for (var i = 0; i < keys.length; i++) {

            console.log("Building Product Choose Box", product.optionsByType[keys[i]]);

            // Get the key name
            var key = keys[i];
            var option = product.optionsByType[key];

            // Step 3: Sort the options in optionsByType according to the lookup map
            if (key in optionLookup) {
                option.sort(function (a, b) {
                    return optionLookup[key].indexOf(a) - optionLookup[key].indexOf(b);
                });
            }

            // Create the radio group
            var radioGroup = {
                n: "ua.select.group",
                name: key,
                title: key,
                required: true,
                inner: []
            };

            if (option.length == 1) {

                //check are all value's numbers if so order them by number, if they have text characters don't touch them
                if (option.every(function (val) { return !isNaN(val); })) {
                    option.sort(function (a, b) {
                        return a - b;
                    });
                }

                // For each option, create a radio button
                for (var j = 0; j < option.length; j++) {


                    radioGroup.inner.push({
                        title: option[j],
                        value: option[j]
                    });

                    // If there is only 1
                    if (option.length == 1) {
                        radioGroup.inner[0].checked = true;
                    }
                }

                radioGroup.c = "d-none";

                var holdingLabel = {
                    n: "label",
                    i: `${key}: ${option[0]}`,
                    c: "pb-0 fs-4 d-block"
                }

                // Add the radio group to the add to cart div
                addtoCardDiv.i.push(radioGroup, holdingLabel);

            } else {

                // For each option, create a radio button
                for (var j = 0; j < option.length; j++) {
                    radioGroup.inner.push({
                        title: option[j],
                        value: option[j]
                    });

                    // If there is only 1
                    if (option.length == 1) {
                        radioGroup.inner[0].checked = true;
                    }
                }

                // Add the radio group to the add to cart div
                addtoCardDiv.i.push(radioGroup);
            }
        }
    }



    //! Old Variation
    //     if ("options" in product) {

    //         //options will look like 
    //         // [
    //         //     {
    //         //         "id": "gid://shopify/ProductOption/10266497712368",
    //         //         "name": "Color",
    //         //         "values": [
    //         //             "Light Denim",
    //         //             "Black",
    //         //             "Dark Denim",
    //         //             "Pink/Orange",
    //         //             "BLUE/ORANGE"
    //         //         ]
    //         //     },
    //         //     {
    //         //         "id": "gid://shopify/ProductOption/10266497777904",
    //         //         "name": "Size",
    //         //         "values": [
    //         //             "XS",
    //         //             "S",
    //         //             "M",
    //         //             "L",
    //         //             "XL"
    //         //         ]
    //         //     }
    //         // ]

    //             // Convert the options array into an optionsByType-like structure
    //             var optionsByType = {};

    //             for (var i = 0; i < product.options.length; i++) {
    //                 var option = product.options[i];
    //                 optionsByType[option.name] = option.values;
    //             }

    //             // Proceed with the same logic as optionsByType
    //             var keys = Object.keys(optionsByType);

    //             for (var i = 0; i < keys.length; i++) {
    //                 console.log("Building Product Choose Box", optionsByType[keys[i]]);

    //                 //get key name
    //                 var key = keys[i];
    //                 var option = optionsByType[key];

    //                 //create the radio group
    //                 var radioGroup = {
    //                     n: "ua.select.group",
    //                     name: key,
    //                     title: key,
    //                     required: true,
    //                     inner: []
    //                 };

    //                 if (option.length == 1) {
    //                     //for each option create a radio button
    //                     for (var j = 0; j < option.length; j++) {
    //                         radioGroup.inner.push({
    //                             title: option[j],
    //                             value: option[j]
    //                         });

    //                         //if there is only 1
    //                         if (option.length == 1) {
    //                             radioGroup.inner[0].checked = true;
    //                         }
    //                     }

    //                     radioGroup.c = "d-none";

    //                     var holdingLabel = {
    //                         n: "label",
    //                         i: `${key}: ${option[0]}`,
    //                         c: "pb-0 fs-4 d-block"
    //                     };

    //                     //add the radio group to the add to card div
    //                     addtoCardDiv.i.push(radioGroup, holdingLabel);

    //                 } else {
    //                     //for each option create a radio button
    //                     for (var j = 0; j < option.length; j++) {
    //                         radioGroup.inner.push({
    //                             title: option[j],
    //                             value: option[j]
    //                         });

    //                         //if there is only 1
    //                         if (option.length == 1) {
    //                             radioGroup.inner[0].checked = true;
    //                         }
    //                     }

    //                     //add the radio group to the add to card div
    //                     addtoCardDiv.i.push(radioGroup);
    //                 }
    //             }


    //     } else if ("optionsByType" in product) {

    //         /**
    //          * Example options by type object
    //          * 
    // {
    //     "Color": [
    //         "Light Denim",
    //         "Pink/Orange",
    //         "BLUE/ORANGE"
    //     ],
    //     "Size": [
    //         "L",
    //         "XS",
    //         "S",
    //         "M",
    //         "XL"
    //     ]
    // }
    //          */

    //         var keys = Object.keys(product.optionsByType);

    //         for (var i = 0; i < keys.length; i++) {

    //             console.log("Building Product Choose Box", product.optionsByType[keys[i]]);

    //             //get key name
    //             var key = keys[i];
    //             var option = product.optionsByType[key];

    //             //create the radio group
    //             var radioGroup = {
    //                 n: "ua.select.group",
    //                 name: key,
    //                 title: key,
    //                 required: true,
    //                 inner: []
    //             };


    //             if (option.length == 1) {

    //                  //for each option create a radio button
    //                  for (var j = 0; j < option.length; j++) {
    //                     radioGroup.inner.push({
    //                         title: option[j],
    //                         value: option[j]
    //                     });

    //                     //if there is only 1
    //                     if (option.length == 1) {
    //                         radioGroup.inner[0].checked = true;
    //                     }

    //                 }


    //                 radioGroup.c = "d-none";

    //                 var holdingLabel = {
    //                     n: "label",
    //                     i: `${key}: ${option[0]}`,
    //                     c: "pb-0 fs-4 d-block"
    //                 }


    //                 //add the radio group to the add to card div
    //                 addtoCardDiv.i.push(radioGroup, holdingLabel);

    //             } else {

    //                 //for each option create a radio button
    //                 for (var j = 0; j < option.length; j++) {
    //                     radioGroup.inner.push({
    //                         title: option[j],
    //                         value: option[j]
    //                     });

    //                     //if there is only 1
    //                     if (option.length == 1) {
    //                         radioGroup.inner[0].checked = true;
    //                     }

    //                 }

    //                 //add the radio group to the add to card div
    //                 addtoCardDiv.i.push(radioGroup);

    //             }

    //         }

    //     }

    //add the cart button
    var btn = {
        n: "div",
        c: "row m-0 pt-3 pb-3",
        i: [{
            n: "div",
            c: "col-lg-6"
        }, {
            n: "button",
            c: "product-add-to-cart btn btn-outline-primary bg-black text-white text-black-hover col-12 col-lg-6",
            id: "shop-add-to-cart",
            i: [{
                n: "i",
                c: "bi bi-cart-check",
                i: "&nbsp;"
            }, "Add to Cart"]
        }]
    };

    addtoCardDiv.i.push(btn);

    return addtoCardDiv;
}

function GenerateBreadcrumbs(product) {

    var productType = shopifySearch.FindType(product.productType);
    var vendor = shopifySearch.FindDesigner(product.vendor);

    var breadcrumb = {
        n: "ua.breadcrumb",
        c: "p-0 m-0 bg-transparent text-uppercase fs-6",
        s: "letter-spacing:1px;",
        i: [{
            n: "ua.navbar.item",
            icon: "bi bi-house",
            type: "icon-only",
            title: "Home",
            src: "../../../../../../../"
        }]
    };

    // if (product.productType) {

    //     //is it a string?
    //     if (typeof productType === "string") {
    //         // productType = shopifySearch.FindType(product.productType);

    //         uai.getObject(`touch?type=${productType}`, "json");

    //     } else {

    //         var noNumName = productType.name;

    //         //remove the number from the begining of the product type
    //         if (productType.name.match(/^\d+\s/)) {
    //             noNumName = productType.name.replace(/^\d+\s/, "");
    //         }

    //         breadcrumb.i.push({
    //             n: "ua.navbar.item",
    //             title: noNumName,
    //             src: `../../../../../../../type/${productType.handle}`
    //         });
    //     }


    // }

    if (product.vendor) {
        // var vendor = shopifySearch.FindDesigner(product.vendor);
        var vendor = product.vendor;
        if (typeof vendor === "string") {
            vendor = shopifySearch.FindDesigner(vendor);
        }

        if (vendor) {

            breadcrumb.i.push({
                n: "ua.navbar.item",
                title: vendor.name,
                src: `../../../../../../../designer/${vendor.handle}`
            });

        }

    }

    return breadcrumb;

}

/**
 * Create a media carousel for the product.
 * @param {*} product The product to create the carousel for.
 * @returns A ua/json object for a media carousel
 */
function createMediaCarousel2(product) {

    var caroseul = {
        n: "ua.carousel",
        inner: [],
    };

    var media = [];
    var images = [];
    var desktopMedia = [];
    var desktopImages = [];

    //for each video (first) and image create a carousel item
    if (product.media.videos) {
        product.media.videos.forEach(function (video) {
            media.push({
                interval: 10000,
                inner: CreateVideoPlayer(video),
                icon: "bi bi-play-btn-fill",
                iconHover: "bi bi-play-btn-fill",
            });
            desktopMedia.push({
                n: "div",
                c: "col-6 p-2 desktop-product-media",
                i: {
                    n: "div",
                    c: "rounded",
                    i: CreateVideoPlayer(video)
                }
            });
        });
    }

    if (product.media.images) {
        product.media.images.forEach(function (image) {
            images.push({
                inner: CreateImagePreview(image),
                interval: 5000
            });
            desktopImages.push({
                n: "div",
                c: "col-6 p-2 desktop-product-media",
                i: {
                    n: "div",
                    c: "rounded",
                    i: CreateImagePreview(image)
                }
            });
        });
    }

    //is their media
    if (media.length > 0) {
        //is their images

        if (images.length > 0) {
            //add the first image than all the media and the rest of the images
            caroseul.inner = [images[0], ...media, ...images.slice(1)];
            // return caroseul;
        } else {
            caroseul.inner = media;
        }

    } else {

        caroseul.inner = images;
    }

    var desktopVProductView = {
        n: "div",
        c: "row d-none d-md-flex",
        i: []
    };

    //If I have ad least one desktopImage
    if (desktopImages.length > 0) {
        desktopVProductView.i.push(desktopImages[0]);
    }

    //if I have at least one desktopMedia
    if (desktopMedia.length > 0) {
        desktopVProductView.i.push(desktopMedia[0]);
    }

    //add the rest of the desktop images and media if they exist
    if (desktopImages.length > 1) {
        desktopVProductView.i.push(...desktopImages.slice(1));
    }

    if (desktopMedia.length > 1) {
        desktopVProductView.i.push(...desktopMedia.slice(1));
    }

    // console.log("Desktop Product View", desktopVProductView);
    // throw "hault";

    return {
        n: "div",
        c: "product-media-holster",
        i: [{
            n: "div",
            c: "d-block d-md-none",
            i: caroseul
        }, desktopVProductView]
    };

}

function GenerateSharer(product, firstImage) {

    var info = {
        realUri: uai.resolveURL("@!/product/" + product.handle),
        uri: "",
        title: "",
        description: "",
        image: ""
    };

    try {
        info.uri = encodeURIComponent(info.realUri);
        info.title = encodeURIComponent(product.title);
        info.description = encodeURIComponent(product.description);

        if (firstImage) {
            info.image = encodeURIComponent(firstImage.src);
        }

        // info.image = encodeURIComponent(product.media.images[0].src);
    } catch (e) {
        // info.uri = "../../../../../    "
        console.error("Failed to generate share info", e);
    }

    console.log("Share Info", info);

    var sharerDiv = {
        "n": "div",
        "c": "sharer row row-reset text-center",
        i: [{
            "n": "div",
            "c": "col-12 p-2",
            "i": "Share"
        }]
    };

    var sharerFB = {
        "n": "a",
        "c": "sharer-facebook luxe-sharer col",
        "target": "_blank",
        "href": `https://www.facebook.com/sharer/sharer.php?u=${info.uri}&title=${info.title}&description=${info.description}&image=${info.image}`,
        "i": [{
            "n": "i",
            "c": "bi bi-facebook",
            "i": ""
        }]
    };

    var sharerTW = {
        "n": "a",
        "c": "sharer-twitter luxe-sharer col",
        "target": "_blank",
        "href": `https://twitter.com/intent/tweet?url=${info.uri}&text=${info.title}`,
        "i": [{
            "n": "i",
            "c": "bi bi-twitter",
            "i": ""
        }]
    };

    var sharerPT = {
        "n": "a",
        "c": "sharer-pinterest luxe-sharer col",
        "target": "_blank",
        "href": `https://pinterest.com/pin/create/button/?url=${info.uri}&media=${info.image}&description=${info.title}`,
        "i": [{
            "n": "i",
            "c": "bi bi-pinterest",
            "i": ""
        }]
    };

    var sharerBrowser = {
        "n": "a",
        "c": "ua-browser-share luxe-sharer col",
        "href": "#ua-browser-share",
        title: santizeStringForShare(product.title),
        description: santizeStringForShare(product.description),
        uri: santizeStringForShare(info.realUri),
        "i": [{
            "n": "i",
            "c": "bi bi-share",
            "i": ""
        }]
    };

    if (firstImage) {
        sharerBrowser.image = firstImage.src;
    }

    var shareEmailMessage = {
        title: `LuxeLevels: ${info.title}`,
        body: `${info.title}${encodeURIComponent("\n\n")}${info.uri}${encodeURIComponent("\n\n")}${info.description}`
    };

    var shareEmail = {
        "n": "a",
        "c": "sharer-email luxe-sharer col",
        "target": "_blank",
        "href": `mailto:?subject=${shareEmailMessage.title}&body=${shareEmailMessage.body}`,
        "i": [{
            "n": "i",
            "c": "bi bi-envelope",
            "i": ""
        }]
    }

    var colBuffer = {
        "n": "div",
        "c": "col",
        "i": "&nbsp;"
    }

    sharerDiv.i.push(colBuffer, sharerFB, sharerTW, sharerPT, sharerBrowser, shareEmail, colBuffer);
    return sharerDiv;

}

//body .onclick #ua-browser-share

document.addEventListener("click", function (event) {
    //ensure the parent or the target is the browser share
    if (event.target.matches(".ua-browser-share") || event.target.closest(".ua-browser-share")) {

        var getShare = document.querySelector(".ua-browser-share");
        console.log("Share Browser", event.target);
        console.log("Get Share", getShare);

        navigator.share({ title: getShare.getAttribute("title"), text: getShare.getAttribute("description"), url: getShare.getAttribute("uri"), image: getShare.getAttribute("image") });
    }
});

/**
 * Santizes a string about to sent to navigator.share API
 * If string is undefined, null, or empty, return empty string.
 * @param {*} string 
 */
function santizeStringForShare(string) {

    return string ? string : "";

    if (!string) {
        return "";
    }
    // console.info("This is the string...", string);
    //change single quotes to \' for sharing
    return string.replace(/'/g, "\\'");
}

// /**
//  * Saves the code from the iframe.
//  * Called by UATools.
//  * @param {*} name The name of the control.
//  * @param {*} control The control to save.
//  * @param {*} repo The repo (used to send back), with the prevalidation results.
//  * @property {*} repo.success The success object (is this field ready to be saved).
//  * @property {*} repo.data The actual data object that will be saved. JSON encodable only (no functions or promises).
//  * @property {*} repo.errors The error's applied to the object. Should be an array, can have more than one item.
//  * @property {*} repo.errors.input If appliable, the direct input that caused the erorr - it must be an object. If input is not provided the control will be highlighted.
//  * @property {*} repo.errors.input.id The id of the input field, if applicable.
//  * @property {*} repo.errors.input.name The name of the input field if applicable.
//  * @property {*} repo.errors.type The type of error that occured.
//  *  - Supports: "validation" - The input or field or control is invalid
//  *  - Supports: "thowable" - Processing this field caused a throwable to error out.
//  * @property {*} repo.errors.message The message to display to the user.
//  * @returns The repo object with the data to save
//  */
// async function save(name, control, repo) {

//   // console.log("Trying save", {
//   //   name, control, repo
//   // });

//   //get the span
//   var span = control.querySelector("span");
//   repo.data = span.innerText;
//   return repo;

// } module.exports.save = save;

// /**
//  * Loads the control with data.
//  * @param {*} name The name of the control.
//  * @param {*} control The control itself (including placeholder tag).
//  * @param {*} data The data to load into the control.
//  */
// async function load(name, control, data) {

//   //get the span
//   var span = control.querySelector("span");
//   span.innerText = data;

// } module.exports.load = load;