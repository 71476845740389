/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/**
 * Example
 * 
 * {
        "n": "div",
        "class": "tiny-outline bg-blur-og-primary",
        "i": {
          "duration": "30",
          "namespace": "ua.marquee",
          "inner": [
            {
              "alt": "Aldo",
              "class": "img-fluid",
              "src": "http://10.5.0.7:8517/brands/camilla.svg",
              "namespace": "img"
            },
            {
              "alt": "AMS Pure",
              "class": "img-fluid",
              "src": "http://10.5.0.7:8517/brands/ams-pure.png",
              "namespace": "img"
            },
            {
              "alt": "Crislu",
              "class": "img-fluid",
              "src": "http://10.5.0.7:8517/brands/crislu.png",
              "namespace": "img"
            },
            {
              "alt": "Desigul.",
              "class": "img-fluid",
              "src": "http://10.5.0.7:8517/brands/desigul.png",
              "namespace": "img"
            },
            {
              "alt": "Rails",
              "class": "img-fluid",
              "src": "http://10.5.0.7:8517/brands/rails.png",
              "namespace": "img"
            },
            {
              "alt": "Robert Graham",
              "class": "img-fluid",
              "src": "http://10.5.0.7:8517/brands/robert-graham.svg",
              "namespace": "img"
            },
            {
              "alt": "Tricot Chic",
              "class": "img-fluid",
              "src": "http://10.5.0.7:8517/brands/tricot-chic.png",
              "namespace": "img"
            },
            {
              "alt": "zadig & voltaire",
              "class": "img-fluid",
              "src": "http://10.5.0.7:8517/brands/zadig-&-voltaire.svg",
              "namespace": "img"
            }
          ]
        }
      }
    }
 */


// var jsonRender = require("../../uat/src/interface/jsonRender.js");
// var uai = require("../../uat/src/interface/interface.js");
// var shopifySearch = require("../../uam/shopify.search.js");
var acceptableDesigners = require("../public/acceptable.designers.json");

var define = {
    namespace: "luxe.designer.homepage.list",
    title: "Luxe Designer Marquee",
    description: "Display's a designer marquee.",
    fields: {
    }//,
    // supportServer: false
}; module.exports.define = define;


/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

    var element = {
        "n": "div",
        "c": "text-center",
        // "class": "tiny-outline bg-blur-og-primary overflow-hidden",
        "i":[]
    };

    // var acceptableDesigners = [
    //   "camilla", "tricot-chic", "carla-ruiz", "paige-denim", "jonathan-simkhai", "amanda-uprichard", "hutch",
    //   "NeroGiardini", "hale-bob", "Samuel Dong"
    // ];

    // var acceptableDesigners = {
    //   "camilla": "../../../../../../../brands/camilla.svg",
    //   "tricot-chic": "../../../../../../../brands/tricot-chic.png",
    //   "carla-ruiz": "../../../../../../../brands/carla-ruiz.png",
    //   "paige-denim": "../../../../../../../brands/paige-denim.png",
    //   "jonathan-simkhai": "../../../../../../../brands/jonathan-simkhai.png",
    //   "amanda-uprichard": "../../../../../../../brands/amanda-uprichard.png",
    //   "hutch": "../../../../../../../brands/hutch.png",
    //   "NeroGiardini": "../../../../../../../brands/nero-giardini.png",
    //   "hale-bob": "../../../../../../../brands/hale-bob.png",
    //   "Samuel Dong": "../../../../../../../brands/samuel-dong.png"

    // }

    // var acceptableDesigners = [
    //   {
    //     name: "camilla",
    //     foreground: "../../../../../../../brands/camilla.svg"
    //   },
    //   {
    //     name: "tricot-chic",
    //     foreground: "../../../../../../../brands/tricot-chic.png"
    //   },
    //   {
    //     name: "carla-ruiz",
    //     foreground: "../../../../../../../brands/carla-ruiz.png"
    //   },
    //   {
    //     name: "paige-denim",
    //     foreground: "../../../../../../../brands/paige.svg"
    //   },
    //   {
    //     name: "jonathan-simkhai", 
    //     foreground: "../../../../../../../brands/simkhai.svg"
    //   },
    //   {
    //     name: "amanda-uprichard",
    //     foreground: "../../../../../../../brands/amandauprichard.svg"
    //   },
    //   {
    //     name: "hutch", 
    //     foreground: "../../../../../../../brands/hutch.png"
    //   },
    //   {
    //     name: "NeroGiardini",
    //     foreground: "../../../../../../../brands/nero-giardini.svg"
    //   },
    //   {
    //     name: "hale-bob",
    //     foreground: "../../../../../../../brands/hale-bob.svg"
    //   },
    //   {
    //     name: "Samuel Dong",
    //     foreground: "../../../../../../../brands/samuel-dong.png"
    //   }
    // ]

    // for each designer in this array
    // var designers = shopifySearch.searchable().designers;

    for (var i = 0; i < acceptableDesigners.length; i++) {
        var designer = acceptableDesigners[i];

        var ele = {
            "n": "luxe.designer.preview",
            "type": "list", 
            ...designer
        };

        delete ele.foreground;

        element.i.push(ele);


        // // if (acceptableDesigners.length > 0) {
        // //     if (acceptableDesigners.indexOf(designer.handle) == -1) {
        // //         continue;
        // //     }
        // // }

        // //clean the designers
        // if ("foreground" in designer) {
        //     if (designer.foreground == "#{foreground}") {
        //         delete designer.foreground;
        //     }
        // }

        // if ("foreground" in designer) {
        //     marquee.i.inner.push({
        //         "alt": designer.name,
        //         "class": "img-fluid",
        //         "src": designer.foreground,
        //         "namespace": "img"
        //     });
        // }
     
    }

    return element;

} 